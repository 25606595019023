<template lang="pug">
  .add-base-rate-period-form
    .base-rate-period-selection
      p.form-title {{ $t("plan_management.modal.base_rate_period_group") }}
      AppDropdown(
        searchable
        checkbox
        batch-select
        multiple
        allow-empty
        :value="selectedBaseRatePeriodGroups"
        :items="baseRatePeriodSettings"
        @select="selectBaseRatePeriodGroups"
      )
    .time-slot-selection(v-if="isTimeSlotSelectionShown")
      template(v-for="{ id, name } in selectedBaseRatePeriodGroups")
        p.form-title {{ `${$t("plan_management.modal.time_slot")}: ${name}` }}
        AppDropdown(
          allow-empty
          close-on-select
          :value="selectedTimeSlots[id]"
          :items="timeSLots"
          @select="selectTimeSlot(id, $event)"
        )
      .add-button
        AppButton(
          :disabled="isAddNewDisabled"
          title="plan_management.actions.add_new",
          @click="handleAddNew"
        )
</template>

<script>
  // misc
  import { isEmpty, some, forEach } from "lodash-es"

  import baseRatePeriodSettingsModule from "@/config/store/plan_management/base_rate_period_settings"

  import withStoreModule from "@/mixins/withStoreModule"

  import { TIME_SLOT_LIST } from "./../../constants.js"

  const baseRatePeriodSettingsMixin = withStoreModule(baseRatePeriodSettingsModule, {
    name: "baseRatePeriodSettings",
    readers: { baseRatePeriodSettings: "items" },
    actions: { fetchPricePeriods: "FETCH_ITEMS" }
  })

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    mixins: [baseRatePeriodSettingsMixin],

    data() {
      return {
        timeSLots: TIME_SLOT_LIST,
        selectedBaseRatePeriodGroups: [],
        selectedTimeSlots: {}
      }
    },

    mounted() {
      this.fetchPricePeriods({ pagination: { _disabled: true } })
    },

    props: {
      planPriceItem: {
        type: Object,
        required: true
      }
    },

    computed: {
      isTimeSlotSelectionShown() {
        return !isEmpty(this.selectedBaseRatePeriodGroups)
      },

      isAddNewDisabled() {
        return some(this.selectedBaseRatePeriodGroups, ({ id }) => isEmpty(this.selectedTimeSlots[id]))
      }
    },

    methods: {
      selectBaseRatePeriodGroups(baseRatePeriodGroups) {
        this.selectedBaseRatePeriodGroups = baseRatePeriodGroups
        this.selectedTimeSlots = {}
      },

      selectTimeSlot(baseRatePeriodGroupId, timeSlot) {
        this.$set(this.selectedTimeSlots, baseRatePeriodGroupId, timeSlot)
      },

      buildPrices(timeSLot) {
        return timeSLot.slots.map(slot => ({ [slot]: null }))
      },

      buildPriceItem(group) {
        return {
          period_name: group.name,
          period_id: group.id,
          fee_type: this.selectedTimeSlots[group.id].id,
          fees: {
            fee_1: null,
            fee_2: null,
            fee_3: null,
            fee_4: null,
            excess_fee: null
          },
          _addedAt: Date.now()
        }
      },

      handleAddNew() {
        forEach(this.selectedBaseRatePeriodGroups, group => {
          this.planPriceItem.price_items.push(this.buildPriceItem(group))
        })
        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .add-base-rate-period-form
    margin: 0 auto
    width: 300px

    .add-button
      margin-top: 10px
      display: flex
      justify-content: center

      button
        background-color: $default-purple

        ::v-deep
          svg
            path
              fill: $default-white

          .app-button-content
            .title
              color: $default-white

    .form-title
      color: $filter-title-color
      font-size: 0.8rem
      margin-left: 8px
      margin-bottom: 0px
      margin-top: 10px

      &:first-child
        margin-top: 0px
</style>
